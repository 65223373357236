import React from 'react'
import { Box, SimpleGrid, Heading, Link } from '@chakra-ui/react'
import Prize from './Prize'

const Prizes = () => {
  return (
    <Box py={16}>
      <Box textAlign="center" py={16}>
        <Heading fontWeight="regular" fontSize="6xl">
          STAKING PRIZES
        </Heading>
        <Box fontSize="xl" mt={4}>
          Stakers had the chance to win extra prizes including Wizardia NFTs. Find the{' '}
          <Link
            href="https://wizardia.io/blog/wizardia-staking-prize-winners-announced"
            target="_blank"
            color="rgb(122, 235, 228)"
          >
            full list of winners
          </Link>{' '}
          on our blog.
        </Box>
      </Box>
      <SimpleGrid columns={[1, null, 3]} gap={5}>
        <Prize
          image="/img/reward1.png"
          numberImage="/img/1.png"
          name="Arena Genesis NFTs"
          prizeProperties={[
            ['Worth', '$12000'],
            ['Winners', '50'],
            ['Staking period', '12 months'],
          ]}
        />
        <Prize
          image="/img/reward2.png"
          numberImage="/img/2.png"
          name="Wizard NFTs"
          prizeProperties={[
            ['Worth', '$7770'],
            ['Winners', '100'],
            ['Staking period', '8 months'],
          ]}
        />
        <Prize
          image="/img/reward3.png"
          numberImage="/img/3.png"
          name="Staking rewards"
          prizeProperties={[]}
          text="Stake more and stake longer, for even better rewards"
        />
      </SimpleGrid>
    </Box>
  )
}

export default Prizes
