import React from 'react'
import { Box, Image, Container, SimpleGrid, Text, Flex, Link } from '@chakra-ui/react'
import SocialIcons from 'components/SocialIcons'
import CustomDivider from 'components/CustomDivider'
import CustomButton from 'components/CustomButton'

type Props = {
  onStakeClick: () => void
}

const TokenSale = ({ onStakeClick }: Props) => {
  return (
    <Box py={6} border="1px solid rgb(122,235,228)" textAlign="center" borderRadius="2rem">
      <Box fontSize="2xl" fontWeight="bold" textColor="rgb(122,235,228)">
        Token sale is live
      </Box>
      <Box fontSize="xl" mt={4}>
        Buy Wizardia tokens
      </Box>
      <Flex py={6} px={6} gap={5}>
        <CustomButton
          flex={1}
          py={2}
          px={2}
          onClick={() => window.open('https://wizardia.co/3qmjcv1', '_blank')}
        >
          <Image src="/img/gate.png" h="30px" />
        </CustomButton>
        <CustomButton
          flex={1}
          py={2}
          px={2}
          onClick={() =>
            window.open(
              'https://pancakeswap.finance/info/pool/0xee456d906a38e10680c9d157fff143390e9681ba',
              '_blank',
            )
          }
        >
          <Image src="/img/pancake.png" h="26px" />
        </CustomButton>
      </Flex>
      <Flex alignItems="center" mb={6} mx={8}></Flex>
    </Box>
  )
}

export default TokenSale
