import React, { useState } from 'react'
import { useDisclosure, Box, Center } from '@chakra-ui/react'
import CustomButton from 'components/CustomButton'
import Modal from 'components/Modal'
import Loader from 'components/Loader'
import { networkConfigs } from 'data/networks'
import { PoolInfo } from 'types/pool'
import { useWallet } from 'providers/WalletProvider'
import { useTokenApproval } from 'hooks/useTokenApproval'
import { pushAnalyticsEvent } from 'utils/analytics'

type Props = {
  pool: PoolInfo
  amount: number
  stakeTokens: (poolInfo: PoolInfo, amount: number) => Promise<boolean>
  onStake: () => void
}

const LockButton = ({ pool, amount, stakeTokens, onStake }: Props) => {
  const { account, chainId } = useWallet()
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  const [isStakeLoading, setIsStakeLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {
    isApproved,
    isLoading: isApprovalLoading,
    approve,
  } = useTokenApproval({ account, spender: pool.address })

  const isLoading = isStakeLoading || isApprovalLoading

  if (!pool.details) {
    return null
  }

  const handleLockClick = async () => {
    if (!account || !pool.details) {
      return
    }
    if (amount === 0) {
      setErrorMessage('Please select a valid amount to lock')
      openModal()
      return
    }
    if (chainId && chainId !== pool.chainId) {
      setErrorMessage(
        `Please switch to ${networkConfigs[pool.chainId].chainName} network and try again`,
      )
      openModal()
      return
    }
    if (!pool.details.isOpen) {
      setErrorMessage('Pool is closed')
      openModal()
      return
    }
    setErrorMessage(null)
    openModal()
  }

  const handleApproveClick = async () => {
    if (!account || !pool.details) {
      return
    }
    setIsStakeLoading(true)
    const result = await approve()
    setIsStakeLoading(false)
    if (!result) {
      setErrorMessage('Failed to Approve')
    }
  }

  const handleStakeClick = async () => {
    if (!account || !pool.details) {
      return
    }
    setIsStakeLoading(true)
    const result = await stakeTokens(pool, amount)
    setIsStakeLoading(false)
    if (result) {
      pushAnalyticsEvent({
        event: 'stakingComplete',
        stakingAmount: amount,
        stakingApy: pool.apy,
        stakingNetwork: networkConfigs[pool.chainId].chainName,
      })
      closeModal()
      onStake()
    } else {
      setErrorMessage('Failed to Stake')
    }
  }

  return (
    <>
      <CustomButton color="blue" disabled={!pool.details.isOpen} onClick={handleLockClick} py={4}>
        Stake
      </CustomButton>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={errorMessage ? 'Error' : 'Stake tokens'}
      >
        {errorMessage ?? (
          <Box>
            <Box mb={2}>AMOUNT</Box>
            <Box fontSize="xl" fontWeight="bold">
              {amount} {pool.details.token.symbol}
            </Box>
            <Box pt={6}>
              {isLoading && (
                <Center mt={2}>
                  <Loader text="Waiting for transaction, it might take a while" />
                </Center>
              )}
              {!isLoading && isApproved && (
                <CustomButton color="blue" onClick={handleStakeClick} py={4}>
                  STAKE
                </CustomButton>
              )}
              {!isLoading && !isApproved && (
                <CustomButton color="blue" onClick={handleApproveClick} py={4}>
                  APPROVE
                </CustomButton>
              )}
            </Box>
          </Box>
        )}
      </Modal>
    </>
  )
}

export default LockButton
