import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import SocialIcon from './SocialIcon'

const SocialIcons = () => {
  return (
    <SimpleGrid columns={[3, null, 5]} gap={5}>
      <SocialIcon name="fab fa-discord" href="https://discord.gg/wizardia" />
      <SocialIcon name="fab fa-telegram-plane" href="https://t.me/joinchat/tKpwLyjGJfM2ODE0" />
      <SocialIcon name="fab fa-twitter" href="https://twitter.com/PlayWizardia" />
      <SocialIcon name="fab fa-medium" href="https://medium.com/" />
      <SocialIcon name="fab fa-tiktok" href="https://tik-tok.com/@wizardia" />
    </SimpleGrid>
  )
}

export default SocialIcons
