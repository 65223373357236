import React from 'react'
import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { fromWei } from 'web3-utils'
import ErrorMessage from 'components/ErrorMessage'
import CustomBox from 'components/CustomBox'
import CustomButton from 'components/CustomButton'
import { useWallet } from 'providers/WalletProvider'
import { PoolInfo } from 'types/pool'
import { networkConfigs } from 'data/networks'
import PoolStats from './PoolStats'
import WithdrawButton from './WithdrawButton'

type Props = {
  pool?: PoolInfo
  withdrawTokens: (poolInfo: PoolInfo) => Promise<boolean>
  onStakingInfoClick: () => void
}

const BalanceCard = ({ pool, withdrawTokens, onStakingInfoClick }: Props) => {
  const { connect } = useWallet()

  if (!pool) {
    return null
  }

  if (!pool.details) {
    return (
      <Box my={10} px={12} py={8}>
        <Box fontWeight="bold" fontSize="xl" mb={6}>
          Your balance
        </Box>
        <Box>
          Please connect to wallet and switch to {networkConfigs[pool.chainId].chainName} network
        </Box>
        <CustomButton onClick={connect} my={12} py={4}>
          Connect Wallet
        </CustomButton>
      </Box>
    )
  }
  return (
    <CustomBox my={10} px={12} py={8}>
      <Box fontWeight="bold" fontSize="xl" mb={6}>
        Your balance
      </Box>
      <Box border="1px solid rgb(122,235,228,0.5)" borderRadius="1.5rem" px={5} py={5}>
        {pool.details.accountStaked !== null ? (
          <Flex alignItems="center">
            <Box flex={1}>You have staked</Box>
            <Flex flex={1} textAlign="right" alignItems="baseline" justifyContent="right">
              <Box fontSize="2xl" fontWeight="bold" mr={2}>
                {parseFloat(fromWei(pool.details.accountStaked)).toFixed(2)}
              </Box>
              <Box>{pool.details.token.symbol}</Box>
            </Flex>
          </Flex>
        ) : (
          <Box fontSize="lg" fontWeight="bold">
            <ErrorMessage message="Please connect to wallet" />
          </Box>
        )}
      </Box>
      <PoolStats pool={pool} />
      <Box mt={16}>
        {pool.details.accountStaked !== null ? (
          <WithdrawButton
            isDisabled={
              !pool.details.accountStaked ||
              pool.details.accountStaked === '0' ||
              !pool.details.isOpen
            }
            pool={pool}
            withdrawTokens={withdrawTokens}
          />
        ) : (
          <Box mt={10} fontWeight="bold" textAlign="center">
            <ErrorMessage message="Please connect to wallet to lock and withdraw tokens" />
          </Box>
        )}
      </Box>
      <SimpleGrid columns={[1, null, 2]} gap={3} mt={6}>
        <CustomButton flex={1} onClick={onStakingInfoClick} py={4}>
          What is staking?
        </CustomButton>
        <CustomButton
          flex={1}
          onClick={() => {
            window.open('https://wizardia.co/3L4O0sa', '_blank')
          }}
          py={4}
        >
          Where to buy tokens?
        </CustomButton>
      </SimpleGrid>
    </CustomBox>
  )
}

export default BalanceCard
