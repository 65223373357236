import React from 'react'
import {
  AccordionPanel,
  SimpleGrid,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Heading,
  Link,
} from '@chakra-ui/react'

const Faq = () => {
  const items = [
    {
      headline: 'What is staking?',
      content: (
        <Box>
          Staking is a way to earn rewards for holding Wizardia&apos;s $WZRD cryptocurrency in a
          staking pool that Wizardia provides.
        </Box>
      ),
    },
    {
      headline: 'How does staking work?',
      content: (
        <Box>
          You can &quot;stake&quot; some of your $WZRD token holdings and earn a percentage as a
          reward over time. This happens via a “staking pool” which you can think of as being
          similar to an interest-bearing savings account. We will have different staking pools with
          different percentages/durations to meet everyone&apos;s needs.
        </Box>
      ),
    },
    {
      headline: 'Where can I stake my Wizardia Tokens (WZRD)?',
      content: <Box>You can stake Wizardia Tokens (WZRD) on staking.wizardia.io</Box>,
    },
    {
      headline: 'When will I receive my staking rewards?',
      content: (
        <Box>
          Staking rewards will be transferred to your wallet after the chosen staking period is
          over. We have 3 staking periods: 4, 8, 12 months
        </Box>
      ),
    },
    {
      headline: 'Where will I get my rewards? (Wallet)',
      content: (
        <Box>
          You will receive staking rewards on the same wallet you chose to stake your tokens from.
        </Box>
      ),
    },
    {
      headline: 'Where can I buy Wizardia Tokens (WZRD)?',
      content: (
        <Box>
          $WZRD tokens can be bought on{' '}
          <Link
            href="https://www.gate.io/trade/WZRD_USDT"
            target="_blank"
            color="rgb(122, 235, 228)"
          >
            Gate.io
          </Link>{' '}
          (as a WZRD/USDT pair) and on{' '}
          <Link
            href="https://pancakeswap.finance/info/pool/0xee456d906a38e10680c9d157fff143390e9681ba"
            target="_blank"
            color="rgb(122, 235, 228)"
          >
            PancakeSwap
          </Link>
          .
        </Box>
      ),
    },
  ]

  return (
    <Box py={16}>
      <Box textAlign="center" py={16}>
        <Heading fontWeight="regular" fontSize="4xl">
          FREQUENTLY ASKED QUESTIONS
        </Heading>
      </Box>
      <Accordion allowToggle>
        <SimpleGrid columns={[1, null, 2]} gap={5}>
          {items.map(item => (
            <Box key={item.headline}>
              <AccordionItem border={0}>
                {({ isExpanded }) => (
                  <Box
                    border={`1px solid ${
                      isExpanded ? 'rgb(122, 235, 228)' : 'rgb(255, 255, 255, 0.3)'
                    }`}
                    borderRadius="2rem"
                    p={3}
                  >
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize="xl" fontWeight="bold">
                        {item.headline}
                      </Box>
                      <AccordionIcon textColor="rgb(122,235,228)" />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{item.content}</AccordionPanel>
                  </Box>
                )}
              </AccordionItem>
            </Box>
          ))}
        </SimpleGrid>
      </Accordion>
    </Box>
  )
}

export default Faq
