import React from 'react'
import { Link } from '@chakra-ui/react'

type Props = {
  name: string
  href: string
}

const SocialIcon = ({ name, href }: Props) => {
  return (
    <Link href={href} _hover={{ color: 'rgb(122,235,228)' }}>
      <i className={name}></i>
    </Link>
  )
}

export default SocialIcon
