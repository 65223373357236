import React from 'react'
import {
  Box,
  Flex,
  Image,
  Link,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerContent,
  CloseButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Center,
} from '@chakra-ui/react'
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { getEllipsisText } from 'utils/formatters'
import { useWallet } from 'providers/WalletProvider'

export default function Navbar() {
  const { account, connect, disconnect } = useWallet()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const links = [
    { url: 'https://wizardia.io/', text: 'About' },
    { url: 'https://www.gate.io/trade/WZRD_USDT', text: 'Buy $WZRD' },
  ]

  const disconnectWallet = () => {
    disconnect()
    window.location.reload()
  }

  return (
    <>
      <Flex
        justifyContent="center"
        bg="linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)"
        px={4}
        position="fixed"
        w="100%"
        zIndex={100}
        display={{ base: 'none', md: 'flex' }}
      >
        <Flex flex={1} h={24} px={4} maxW="1300px">
          <Flex alignItems="center" flex={1} h="100%">
            <Link href="https://wizardia.io" mr={10}>
              <Image height="21px" width="145px" src="/img/logo.svg" />
            </Link>
            {links.map(link => (
              <Link
                key={link.url}
                href={link.url}
                fontSize="sm"
                fontWeight="bold"
                mx={4}
                _hover={{ textDecoration: 'none', color: 'rgb(122,235,228)' }}
              >
                {link.text}
              </Link>
            ))}
          </Flex>
          <Center>
            <Menu>
              <MenuButton>
                <Flex alignItems="center" fontWeight="bold" fontSize="sm">
                  <Box mr={2}>Wallet status:</Box>
                  <Box color={account ? 'rgb(122,235,228)' : '#ffdf70'}>
                    {account ? getEllipsisText(account) : 'Not connected'}
                  </Box>
                  <ChevronDownIcon color={account ? 'rgb(122,235,228)' : '#ffdf70'} ml={2} />
                </Flex>
              </MenuButton>
              <MenuList
                bg="rgb(0,0,0)"
                border="1px solid rgb(122,235,228)"
                borderRadius="2rem 0.5rem 2rem 2rem"
                p={4}
              >
                {account ? (
                  <MenuItem onClick={disconnectWallet} borderRadius="xl">
                    Disconnect
                  </MenuItem>
                ) : (
                  <MenuItem onClick={connect} borderRadius="xl">
                    Connect Wallet
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Center>
        </Flex>
      </Flex>
      <Flex h={24} px={5} alignItems="center" display={{ base: 'flex', md: 'none' }}>
        <Link flex={1} href="https://wizardia.io" mr={6}>
          <Image height="21px" width="145px" src="/img/logo.svg" />
        </Link>
        <IconButton
          icon={<HamburgerIcon />}
          onClick={onOpen}
          bg="transparent"
          aria-label="open menu"
        />
      </Flex>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent bg="#000000">
          <Flex alignItems="center" py={6} px={6}>
            <Link flex={1} href="https://wizardia.io">
              <Image height="21px" width="145px" src="/img/logo.svg" />
            </Link>
            <CloseButton onClick={onClose} />
          </Flex>
          <Box mt={12}>
            {links.map(link => (
              <Box
                key={link.url}
                py={6}
                fontWeight="bold"
                fontSize="xl"
                textAlign="center"
                textTransform="uppercase"
              >
                <Link
                  href={link.url}
                  mx={3}
                  _hover={{ textDecoration: 'none', color: 'rgb(122,235,228)' }}
                >
                  {link.text}
                </Link>
              </Box>
            ))}
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  )
}
