import React, { useRef, useState } from 'react'
import { Container, Box, Heading, SimpleGrid, Center, Spinner, Text, Link } from '@chakra-ui/react'
import StakingCard from 'components/StakingCard'
import BalanceCard from 'components/BalanceCard'
import ErrorMessage from 'components/ErrorMessage'
import TokenSale from 'components/TokenSale'
import Prizes from 'components/Prizes'
import Faq from 'components/Faq'
import { PoolInfo } from 'types/pool'
import { useWallet } from 'providers/WalletProvider'
import { pools as poolsInfo } from 'data/pools'
import { usePools } from 'hooks/usePools'
import Loader from 'components/Loader'

const Staking = () => {
  const { account, chainId } = useWallet()
  const [selectedPoolAddress, setSelectedPoolAddress] = useState(poolsInfo[0].address)

  const { pools, reload, stakeTokens, withdrawTokens, isLoading } = usePools({
    poolsInfo,
    account,
    chainId,
  })

  const faqSectionRef = useRef<any>(null)
  const stakingSectionRef = useRef<any>(null)

  const handlePoolChange = (pool: PoolInfo) => {
    setSelectedPoolAddress(pool.address)
  }

  const flyToFaq = () => {
    faqSectionRef.current?.scrollIntoView()
  }

  const flyToStaking = () => {
    stakingSectionRef.current?.scrollIntoView()
  }

  return (
    <Box>
      <Container maxW="1300px" mt={16} px={4}>
        <Box mt={24} mb={12}>
          <SimpleGrid columns={[1, null, 2]} gap={8}>
            <Box mb={6}>
              <Heading fontWeight="regular" fontSize="6xl">
                WIZARDIA STAKING
              </Heading>
              <Box fontSize="xl" mt={4}>
                $WZRD staking pools are now closed and prizes have been delivered to stakers. Find
                the {'  '}
                <Link
                  href="https://wizardia.io/blog/wizardia-staking-prize-winners-announced"
                  target="_blank"
                  color="rgb(122, 235, 228)"
                >
                  full list of winners
                </Link>{' '}
                on our blog.
              </Box>
            </Box>
            <Box pl={[0, null, 24]}>
              <TokenSale onStakeClick={flyToStaking} />
            </Box>
          </SimpleGrid>
        </Box>
        <Box ref={stakingSectionRef} textAlign="center" mt={24} mb={8}>
          <Heading fontWeight="regular" fontSize="6xl">
            STAKE TOKENS TO EARN MORE
          </Heading>
          <Box fontSize="xl" mt={4}>
            Wizardia&apos;s staking program rewards token holders with generous token rewards and
            special NFT prizes.
          </Box>
        </Box>
        {isLoading && (
          <Box py={32}>
            <Loader text="Loading" />
          </Box>
        )}
        {!isLoading && pools && (
          <SimpleGrid columns={[1, null, 2]} gap={6}>
            <StakingCard
              pools={pools}
              selectedPool={pools.find(pool => pool.address === selectedPoolAddress)}
              stakeTokens={stakeTokens}
              onPoolChange={handlePoolChange}
              onStake={reload}
            />
            <BalanceCard
              pool={pools.find(pool => pool.address === selectedPoolAddress)}
              withdrawTokens={withdrawTokens}
              onStakingInfoClick={flyToFaq}
            />
          </SimpleGrid>
        )}
        <Prizes />
        <Box ref={faqSectionRef} />
        <Faq />
        {!isLoading && !pools && (
          <Center py={36} fontSize="xl">
            <ErrorMessage message="Something went wrong. Please try again later." />
          </Center>
        )}
      </Container>
    </Box>
  )
}

export default Staking
