import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { primaryToken } from 'data/primaryToken'
import { PoolContractData, PoolInfo } from 'types/pool'
import { chainId as defaultChainId } from 'data/pools'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  poolsInfo: PoolInfo[]
  account: string | null
  chainId: string | null
}

export const usePools = ({ poolsInfo, account, chainId }: Props) => {
  const [pools, setPools] = useState<PoolInfo[]>(poolsInfo)
  const [isLoading, setIsLoading] = useState(false)
  const { walletProvider } = useWallet()

  const getPoolAccountData = async (poolInfo: PoolInfo, maturityDays: number) => {
    if (!account || !walletProvider) {
      return {
        accountStaked: null,
        accountStakedTime: null,
        accountMaturityDate: null,
      }
    }
    const poolContract = new ethers.Contract(poolInfo.address, poolInfo.abi, walletProvider)
    const accountStaked = await poolContract.stakeOf(account)
    const accountStakedTime = await poolContract.stakeTimeOf(account)
    const maturityDate =
      accountStakedTime > 0
        ? new Date(accountStakedTime * 1000 + maturityDays * 24 * 60 * 60 * 1000)
        : null
    return {
      accountStaked: `${accountStaked}`,
      accountStakedTime: `${accountStaked}`,
      accountMaturityDate: maturityDate,
    }
  }

  const getPoolContractData = async (poolInfo: PoolInfo) => {
    if (!walletProvider) {
      return null
    }
    const poolContract = new ethers.Contract(poolInfo.address, poolInfo.abi, walletProvider)
    const tokenAddress = await poolContract.tokenERC20()
    if (!tokenAddress) {
      return null
    }
    const apy = await poolContract.poolApy()
    const stakedTotal = await poolContract.stakedTotal()
    const poolSize = await poolContract.poolSize()
    const maturityDays = await poolContract.maturityDays()
    const launchTime = await poolContract.launchTime()
    const closingTime = await poolContract.closingTime()

    if (apy && stakedTotal && poolSize && closingTime && maturityDays && launchTime) {
      const now = Date.now()
      const launchDate = new Date(parseFloat(launchTime) * 1000)
      const closingDate = new Date(parseFloat(closingTime) * 1000)
      const isOpen =
        now > launchDate.getTime() &&
        now < closingDate.getTime() &&
        parseFloat(poolSize) > parseFloat(stakedTotal)
      const poolData: PoolContractData = {
        token: primaryToken,
        apy: parseFloat(apy),
        stakedTotal: `${stakedTotal}`,
        poolSize: `${poolSize}`,
        maturityDays: parseFloat(maturityDays),
        launchTime: parseFloat(launchTime),
        launchDate,
        closingTime: parseFloat(closingTime),
        closingDate,
        isOpen,
        accountStaked: null,
        accountStakedTime: null,
        accountMaturityDate: null,
      }
      if (account) {
        const { accountStaked, accountStakedTime, accountMaturityDate } = await getPoolAccountData(
          poolInfo,
          poolData.maturityDays,
        )
        poolData.accountStaked = accountStaked
        poolData.accountStakedTime = accountStakedTime
        poolData.accountMaturityDate = accountMaturityDate
      }
      return poolData
    }
    return null
  }

  const reload = async () => {
    const poolsWithDetails = (
      await Promise.all(
        poolsInfo.map(async poolInfo => {
          if (poolInfo.address !== '') {
            const details = await getPoolContractData(poolInfo)
            return { ...poolInfo, details }
          }
          return poolInfo
        }),
      )
    ).filter(poolInfo => !!poolInfo)
    setPools(poolsWithDetails as any)
  }

  const stakeTokens = async (poolInfo: PoolInfo, amount: number) => {
    try {
      if (!poolInfo.details || !walletProvider) {
        return false
      }
      const poolContract = new ethers.Contract(
        poolInfo.address,
        poolInfo.abi,
        walletProvider.getSigner(),
      )
      const amountWei = amount * 10 ** 18
      const amountWeiString = amountWei.toLocaleString('fullwide', { useGrouping: false })
      const tx = await poolContract.stake(amountWeiString)
      await tx.wait()
      await reload()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  const withdrawTokens = async (poolInfo: PoolInfo) => {
    try {
      if (!poolInfo.details || !walletProvider) {
        return false
      }
      const poolContract = new ethers.Contract(
        poolInfo.address,
        poolInfo.abi,
        walletProvider.getSigner(),
      )
      const tx = await poolContract.withdraw()
      await tx.wait()
      await reload()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  useEffect(() => {
    if (chainId !== defaultChainId || !account || !walletProvider) {
      return
    }
    ;(async () => {
      setIsLoading(true)
      await reload()
      setIsLoading(false)
    })()
    // eslint-disable-next-line
  }, [account, chainId, walletProvider])

  return { pools, isLoading, reload, stakeTokens, withdrawTokens }
}
