import React from 'react'
import { Box } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Staking from 'pages/Staking'
import Background from 'components/Background'

function App() {
  return (
    <>
      <Background />
      <Navbar />
      <Box h={24} />
      <Staking />
      <Footer />
    </>
  )
}

export default App
