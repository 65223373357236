import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        background: '#000000',
      },
    },
  },
  fonts: {
    heading: 'EquinoxRegular',
    body: 'Montserrat',
  },
})
