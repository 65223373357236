import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import erc20Abi from 'data/abi/erc20Token.json'
import { primaryToken } from 'data/primaryToken'
import { chainId as defaultChainId } from 'data/pools'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  account: string | null
  chainId: string | null
}

export const useTokenBalance = ({ account, chainId }: Props) => {
  const [balance, setBalance] = useState<string>('0')
  const { walletProvider } = useWallet()

  const getBalance = async () => {
    if (!account || !walletProvider || chainId !== defaultChainId) {
      return
    }
    const tokenContract = new ethers.Contract(primaryToken.address, erc20Abi, walletProvider)
    const tokenBalance = await tokenContract.balanceOf(account)
    setBalance(`${tokenBalance}`)
  }

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line
  }, [account])

  return { balance }
}
