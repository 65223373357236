import { ethers } from 'ethers'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

type WalletInfo = {
  account: string | null
  chainId: string | null
  walletProvider: any
  connect: () => void
  disconnect: () => void
}

type Props = {
  children: any
}

const initialValue = {
  account: null,
  chainId: null,
  walletProvider: null,
  connect: () => {},
  disconnect: () => {},
}

const WalletContext = createContext<WalletInfo>(initialValue)

export const useWallet = () => {
  return useContext(WalletContext)
}

const WalletProvider = ({ children }: Props) => {
  const [account, setAccount] = useState<string | null>(null)
  const [chainId, setChainId] = useState<string | null>(null)
  const [walletProvider, setWalletProvider] = useState<any>()

  const connect = async () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
          },
          network: 'binance',
          chainId: 56,
        },
      },
    }

    const web3Modal = new Web3Modal({
      network: 'mainnet',
      cacheProvider: true,
      providerOptions,
    })

    const instance = await web3Modal.connect().catch(() => null)
    if (!instance) {
      return
    }

    const web3Provider = new ethers.providers.Web3Provider(instance)

    instance.on('chainChanged', async () => {
      window.location.reload()
    })

    instance.on('accountsChanged', async () => {
      window.location.reload()
    })

    const accounts = await web3Provider.listAccounts()
    const chain = (await web3Provider.getNetwork()).chainId
    setAccount(accounts[0])
    setChainId(`0x${chain.toString(16)}`)
    setWalletProvider(web3Provider)
    localStorage.setItem('walletConnected', '1')
  }

  const disconnect = async () => {
    walletProvider.removeAllListeners()
    setWalletProvider(null)
    setAccount(null)
    setChainId(null)
    localStorage.setItem('walletConnected', '0')
  }

  useEffect(() => {
    const wasConnected = localStorage.getItem('walletConnected')
    if (wasConnected === '1') {
      connect()
    }
  }, [])

  return (
    <WalletContext.Provider value={{ account, chainId, walletProvider, connect, disconnect }}>
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
