import React, { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import './index.css'

type Props = {
  children: ReactNode
  color?: 'blue' | 'transparent'
  flex?: number
  px?: number
  py?: number
  mx?: number
  my?: number
  disabled?: boolean
  onClick: () => void
}

const CustomButton = ({
  children,
  color = 'transparent',
  flex,
  px,
  py,
  mx,
  my,
  disabled,
  onClick,
}: Props) => {
  return (
    <Box
      flex={flex}
      onClick={onClick}
      className={`custom-button ${
        color === 'blue' ? 'custom-button-blue' : 'custom-button-transparent'
      }`}
      textAlign="center"
      px={px}
      py={py}
      mx={mx}
      my={my}
      opacity={disabled ? 0.5 : undefined}
    >
      <Flex justifyContent="center">{children}</Flex>
    </Box>
  )
}

export default CustomButton
