import React from 'react'
import { Box, Image, Container, SimpleGrid, Text, Flex, Link } from '@chakra-ui/react'
import SocialIcons from 'components/SocialIcons'
import CustomDivider from 'components/CustomDivider'

const Footer = () => {
  const linkSections = [
    [
      { url: 'https://wizardia.io/', text: 'About' },
      { url: 'https://wizardia.gitbook.io/wizardia-lightpaper/', text: 'Lightpaper' },
      { url: 'https://partners.wizardia.io/', text: 'Affiliate Program' },
      { url: 'https://www.gate.io/trade/WZRD_USDT', text: 'Buy $WZRD' },
    ],
    [
      { url: 'https://wizardia.io/#roadmap', text: 'Roadmap' },
      { url: 'https://wizardia.io/pitch-deck.pdf', text: 'Wizardia pitch deck' },
      {
        url: 'https://intercom.help/wizardia/en/collections/3392228-tokens',
        text: 'Help & Support',
      },
    ],
  ]
  const bottomLinks = [
    {
      url: 'https://wizardia.gitbook.io/wizardia-lightpaper/legals/privacy-policy',
      text: 'Privacy Policy',
    },
    {
      url: 'https://wizardia.gitbook.io/wizardia-lightpaper/legals/terms-and-conditions',
      text: 'Terms of Service',
    },
    {
      url: 'https://wizardia.gitbook.io/wizardia-lightpaper/legals/disclaimer',
      text: 'Disclaimer',
    },
  ]
  return (
    <Box mt={16} py={16}>
      <CustomDivider />
      <Container maxW="1300px" mt={12}>
        <Image height="21px" width="145px" src="/img/logo.svg" mb={8} />
        <SimpleGrid columns={[1, null, 2]}>
          <Box></Box>
          <SimpleGrid columns={[1, null, 3]} gap={3}>
            {linkSections.map(links => (
              <Box>
                {links.map(link => (
                  <Box key={link.url} py={2} fontWeight="bold" fontSize="sm">
                    <Link
                      href={link.url}
                      mx={3}
                      _hover={{ textDecoration: 'none', color: 'rgb(122,235,228)' }}
                    >
                      {link.text}
                    </Link>
                  </Box>
                ))}
              </Box>
            ))}
            <Box>
              <Flex justifyContent={{ base: 'center', md: 'flex-end' }}>
                <SocialIcons />
              </Flex>
            </Box>
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={[1, null, 2]} fontSize="sm" mt={24}>
          <Box>
            <Text fontWeight="bold" py={2} mb={6}>
              © Wizardia. All rights reserved.
            </Text>
          </Box>
          <SimpleGrid columns={[1, null, 3]}>
            {bottomLinks.map(link => (
              <Box key={link.url} py={2} fontWeight="bold">
                <Link href={link.url} mx={3} _hover={{ textDecoration: 'none', opacity: 0.8 }}>
                  {link.text}
                </Link>
              </Box>
            ))}
          </SimpleGrid>
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default Footer
