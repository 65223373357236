import React from 'react'
import { Box, Flex, Text, Center, Circle } from '@chakra-ui/react'
import { PoolInfo } from 'types/pool'
import ProgressBar from 'components/ProgressBar'

type Props = {
  pool: PoolInfo
  isSelected: boolean
  display: string
  onClick: () => void
}

const PeriodButton = ({ pool, isSelected, onClick, display }: Props) => {
  const stakedPercentage = pool.details
    ? (parseFloat(pool.details.stakedTotal) / parseFloat(pool.details.poolSize)) * 100
    : null

  return (
    <Box
      onClick={onClick}
      display={display}
      borderRadius="2rem"
      cursor="pointer"
      borderWidth={1}
      mt="-1px"
      my={2}
      py={4}
      px={5}
    >
      <Flex>
        <Box flex={1} fontWeight="bold">
          <Flex>
            <Center mr={4}>
              <Circle
                size="24px"
                border={
                  isSelected ? '2px solid rgb(122,235,228)' : '2px solid rgb(255,255,255,0.3)'
                }
              />
              {isSelected && <Circle size="14px" bg="rgb(122,235,228)" position="absolute" />}
            </Center>
            <Box flex={1}>APR {pool.apy}%</Box>
          </Flex>
        </Box>
        <Center flex={1}>{pool.months} months</Center>
        <Box flex={1} textAlign="right">
          {stakedPercentage !== null && <Text>{stakedPercentage.toFixed(2)}% staked</Text>}
        </Box>
      </Flex>
      {stakedPercentage !== null && (
        <Box mt={4} mb={1}>
          <ProgressBar value={stakedPercentage} />
        </Box>
      )}
    </Box>
  )
}

export default PeriodButton
