import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

type Props = {
  value: number
}

const ProgressBar = ({ value }: Props) => {
  return (
    <Flex h="10px" border="1px dotted rgba(255,255,255,0.3)" borderRadius="xl" position="relative">
      <Box
        w={`${value}%`}
        bg="linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%)"
        h="6px"
        mx="1px"
        mt="1px"
        borderRadius="xl"
      />
    </Flex>
  )
}

export default ProgressBar
