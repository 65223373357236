import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import CustomButton from 'components/CustomButton'
import Modal from 'components/Modal'
import Loader from 'components/Loader'
import { networkConfigs } from 'data/networks'
import { PoolInfo } from 'types/pool'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  pool: PoolInfo
  isDisabled: boolean
  withdrawTokens: (poolInfo: PoolInfo) => Promise<boolean>
}

const PoolStats = ({ pool, isDisabled, withdrawTokens }: Props) => {
  const { account, chainId } = useWallet()
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!pool.details) {
    return null
  }

  const handleWithdrawClick = async () => {
    if (!account || !pool.details) {
      return
    }
    if (chainId && chainId !== pool.chainId) {
      setErrorMessage(
        `Please switch to ${networkConfigs[pool.chainId].chainName} network and try again`,
      )
      openModal()
      return
    }
    if (!pool.details.accountMaturityDate) {
      setErrorMessage('You have not staked in this pool')
      openModal()
      return
    }
    if (pool.details.accountMaturityDate.getTime() > Date.now()) {
      setErrorMessage('Maturity period is not over yet')
      openModal()
      return
    }
    setIsLoading(true)
    await withdrawTokens(pool)
    setIsLoading(false)
  }

  return (
    <>
      {!isLoading ? (
        <CustomButton color="blue" disabled={isDisabled} onClick={handleWithdrawClick} py={4}>
          Withdraw
        </CustomButton>
      ) : (
        <Loader text="Waiting for transaction" />
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal} title="Error">
        {errorMessage}
      </Modal>
    </>
  )
}

export default PoolStats
