import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import WalletProvider from 'providers/WalletProvider'
import { theme } from 'styles/theme'
import App from './App'
import 'index.css'

const isProduction = process.env.REACT_APP_PRODUCTION !== 'false'

if (isProduction && window.location.protocol !== 'https:') {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`,
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <WalletProvider>
        <App />
      </WalletProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
