import React from 'react'
import { Box, Image, Flex, Center } from '@chakra-ui/react'
import CustomDivider from 'components/CustomDivider'

type Props = {
  image: string
  numberImage: string
  name: string
  text?: string
  prizeProperties: [string, string][]
}

const Prize = ({ image, numberImage, name, text, prizeProperties }: Props) => {
  return (
    <Flex flexDirection="column" mx={12}>
      <Center position="relative" mb={7}>
        <Image src={image} borderRadius="50%" border="1px solid rgb(122,235,228)" />
        <Image src={numberImage} position="absolute" bottom={-7} />
      </Center>
      <Box fontSize="xl" textAlign="center" fontWeight="bold" textColor="rgb(122,235,228)" mt={4}>
        {name}
      </Box>
      <Box mt={6}>
        {prizeProperties.map(property => (
          <Flex key={property[0]}>
            <Box>{property[0]}</Box>
            <Box flex={1} position="relative">
              <Box position="absolute" w="100%" bottom={1}>
                <CustomDivider />
              </Box>
            </Box>
            <Box>{property[1]}</Box>
          </Flex>
        ))}
      </Box>
      <Box textAlign="center">{text}</Box>
    </Flex>
  )
}

export default Prize
