import { PoolInfo } from 'types/pool'
import abi from 'data/abi/stakingPool.json'

export const chainId = '0x38'

export const pools: PoolInfo[] = [
  {
    address: '0x54DA5743F8C01a551E3425b2F1a1CfCaA3a3d060',
    abi,
    chainId,
    apy: 31,
    months: 4,
    details: null,
    active: true,
  },
  {
    address: '0x26f0CCd30fcE000191F1791e1261E9c4561BB83A',
    abi,
    chainId,
    apy: 53,
    months: 8,
    details: null,
    active: true,
  },
  {
    address: '0x88713a269e3126fAf4333Fa588d5ffa4872C2fCe',
    abi,
    chainId,
    apy: 114,
    months: 12,
    details: null,
    active: true,
  },
]
