import React, { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import './index.css'

type Props = {
  children: ReactNode
  color?: 'blue' | 'transparent'
  px?: number
  py?: number
  mx?: number
  my?: number
}

const CustomBox = ({ children, color = 'transparent', px, py, mx, my }: Props) => {
  return (
    <Box
      className={`custom-box ${color === 'blue' ? 'custom-box-blue' : 'custom-box-transparent'}`}
      px={px}
      py={py}
      mx={mx}
      my={my}
    >
      {children}
    </Box>
  )
}

export default CustomBox
