import React from 'react'
import { Box, Image } from '@chakra-ui/react'

const Background = () => {
  return (
    <Box position="absolute" w="100%" zIndex={-1}>
      <Box position="relative">
        <Image src="img/background.webp" w="100%" opacity={0.5} />
        <Image src="img/background2.png" w="100%" />
        <Box
          background="linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)"
          height="300px"
          width="100%"
          bottom={0}
          position="absolute"
        />
      </Box>
    </Box>
  )
}

export default Background
