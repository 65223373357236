import { ethers } from 'ethers'
import { primaryToken } from 'data/primaryToken'
import erc20Abi from 'data/abi/erc20Token.json'
import { useEffect, useState } from 'react'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  account: string | null
  spender: string
}

export const useTokenApproval = ({ account, spender }: Props) => {
  const [isApproved, setIsApproved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { walletProvider } = useWallet()

  const checkAllowance = async () => {
    if (!walletProvider || !account) {
      setIsLoading(false)
      return
    }
    const tokenContract = new ethers.Contract(primaryToken.address, erc20Abi, walletProvider)
    const result = await tokenContract.allowance(account, spender)
    setIsApproved(`${result}` !== '0')
    setIsLoading(false)
  }

  const approve = async () => {
    try {
      if (!walletProvider) {
        return false
      }
      const tokenContract = new ethers.Contract(
        primaryToken.address,
        erc20Abi,
        walletProvider.getSigner(),
      )
      const tx = await tokenContract.approve(spender, '1157920892373161954235709850086879078')
      await tx.wait()
      setIsApproved(true)
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  useEffect(() => {
    checkAllowance()
    // eslint-disable-next-line
  }, [account, spender])

  return { approve, isApproved, isLoading }
}
